import { Link, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import React from "react";
import {
  ServiceOwner as ServiceOwnerType,
  ServiceTags,
} from "../models/Service";

interface ServiceTableProps extends WithStyles<typeof styles> {
  title: string;
  value?: string;
}

interface OwnerOverlayProps {
  open: boolean;
  owner: ServiceOwnerType;
  tags?: ServiceTags;
  onRequestClose(): void;
}

interface ValueProps {
  children?: React.ReactNode;
}

const empty = (value?: string) => !(value && value !== "");

const styles = (theme: Theme) =>
  createStyles({
    badgePadding: {
      paddingRight: theme.spacing(3),
    },
    wrapText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    slPad: {
      paddingLeft: theme.spacing(3),
    },
    paddingDecrease: {
      padding: "0.5em",
    },
  });

const Value = ({ children }: ValueProps) =>
  children && children !== "" ? <span>{children}</span> : <span>-</span>;

const ServiceTableRow = withStyles(styles)(
  ({ classes, title, value }: ServiceTableProps) => (
    <TableRow>
      <TableCell align={"left"} className={classes.paddingDecrease}>
        <DialogContentText>{title} </DialogContentText>
      </TableCell>
      <TableCell align={"left"} className={classes.paddingDecrease}>
        <DialogContentText>
          <Value>{value}</Value>
        </DialogContentText>
      </TableCell>
    </TableRow>
  )
);
const OwnerOverlay = ({
  open,
  onRequestClose,
  owner,
  tags,
}: OwnerOverlayProps) => {
  return (
    <Dialog
      fullWidth
      onClose={onRequestClose}
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="dialog-title">Service information</DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <ServiceTableRow title="Owner" value={owner.owner} />
            <ServiceTableRow title="Team" value={owner.team} />
            <ServiceTableRow title="Segment" value={owner.segment} />
            <ServiceTableRow title="Vertical" value={owner.vertical} />
            <ServiceTableRow
              title="AWS Account Email"
              value={owner.awsAccountEmail}
            />
            <TableRow>
              <TableCell
                align={"left"}
                colSpan={2}
                style={{ borderBottom: "none", padding: "1em" }}
              >
                <DialogContentText
                  style={{
                    fontWeight: "bold",
                    fontSize: "large",
                  }}
                >
                  Tags
                </DialogContentText>
              </TableCell>
            </TableRow>
            {checkTags(tags || {}, owner) ? (
              <TableRow>
                <TableCell
                  align={"left"}
                  colSpan={2}
                  style={{ borderBottom: "none", padding: "0.1em" }}
                >
                  <DialogContentText
                    style={{ fontSize: "small", paddingLeft: "0.375em" }}
                  >
                    Please refer to the tagging guidelines{" "}
                    <Link
                      href="https://docs.cloud.scout24.com/best-practices/tagging/#recommended-tags"
                      target="_blank"
                    >
                      here
                    </Link>
                    .
                  </DialogContentText>
                </TableCell>
              </TableRow>
            ) : null}
            {!tags
              ? null
              : Object.keys(tags || {}).map((key, index) => {
                  return (
                    <TableRow key={index.toString()}>
                      <TableCell align={"left"} style={{ padding: "0.5em" }}>
                        <DialogContentText>{key}</DialogContentText>
                      </TableCell>
                      <TableCell align={"left"} style={{ padding: "0.5em" }}>
                        <DialogContentText>{tags[key]}</DialogContentText>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRequestClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function checkTags(tags: ServiceTags, owner: ServiceOwnerType): boolean {
  return (
    !tags ||
    Object.keys(tags).length <= 0 ||
    empty(owner.owner) ||
    empty(owner.team) ||
    empty(owner.segment) ||
    empty(owner.vertical)
  );
}

export default OwnerOverlay;
