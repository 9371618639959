import React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Moment from "react-moment";

import {
  Service,
  ServiceOwner as ServiceOwnerType,
  ServiceTags,
} from "../models/Service";
import HeaderWithHelp from "./HeaderWithHelp";
import ServiceActions from "./ServiceActions";
import ServiceName from "./ServiceName";
import ServiceOwner from "./ServiceOwner";
import ServiceState from "./ServiceState";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2),
      width: theme.spacing(2),
    },
    buttonIcon: {
      marginRight: 4,
    },
    table: {
      minWidth: 700,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    witdh5: {
      width: "5%",
    },
    witdh10: {
      width: "10%",
    },
    witdh15: {
      width: "15%",
    },
    witdh25: {
      width: "25%",
    },
    versionText: {
      maxWidth: 100,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    paginatorPagesSelect: {
      paddingTop: "6px",
      paddingBottom: "4px",
    },
    center: {
      textAlign: "center",
    },
  });

interface Props extends WithStyles<typeof styles> {
  services: Service[];
  onFilterNamespaceChange: any;
  loadData: () => () => void;
  fetchState: string;
  errorMessage?: string;
}

const ServiceList = withStyles(styles)(
  ({ services, fetchState, errorMessage, loadData, classes }: Props) => {
    const [page, setPage] = React.useState(0);
    const [selectedOwner, setSelectedOwner] = React.useState<ServiceOwnerType>(
      {}
    );
    const [selectedTags, setSelectedTags] = React.useState<ServiceTags>({});
    const [open, setOpen] = React.useState(false);
    const close = () => {
      setOpen(false);
    };
    const doOpen = (owner: ServiceOwnerType, tags?: ServiceTags) => () => {
      setSelectedOwner(owner);
      setSelectedTags(tags || {});
      setOpen(true);
    };
    const [, setColumnHovered] = React.useState("");
    const cancelHover = () => {
      setColumnHovered("");
    };
    const startHover = (key: string) => () => {
      setColumnHovered(key);
    };
    const [rowsPerPage, setRowsPerPage] = React.useState(
      parseInt(
        new URLSearchParams(window.location.search).get("rowsPerPage") || "10",
        10
      )
    );

    const displayedServices = services.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    function handleChangePage(_event: any, newPage: number) {
      setPage(newPage);
    }

    function handleChangeRowsPerPage(event: any) {
      setRowsPerPage(event.target.value);
    }

    switch (fetchState) {
      case "INIT":
        loadData();
        return (
          <Grid container direction="row" justify="center" alignItems="center">
            <p>Initializing application...</p>
          </Grid>
        );
      case "LOADING":
        return (
          <Grid container direction="row" justify="center" alignItems="center">
            <p>Fetching data...</p>
          </Grid>
        );
      case "LOADED":
        return (
          <Paper>
            <div className={classes.tableWrapper}>
              <ServiceOwner
                open={open}
                owner={selectedOwner}
                tags={selectedTags}
                onRequestClose={close}
              />
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <HeaderWithHelp title="Service Name" helpText="" />
                    <HeaderWithHelp
                      title="Status"
                      helpText="Number of running and desired instances of your service. The frame colour indicates the service status. Hover to see the status."
                    />
                    <HeaderWithHelp
                      title="Namespace"
                      helpText="The namespace is the account in which your service is deployed."
                    />
                    <HeaderWithHelp
                      title="Version"
                      helpText="Version tag of the docker container that is currently deployed."
                    />
                    <HeaderWithHelp title="Last updated" helpText="" />
                    <HeaderWithHelp
                      title="Actions"
                      helpText="Menu with links providing further information about your service."
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedServices && displayedServices.length ? (
                    displayedServices.map((service) => (
                      <TableRow key={`${service.name}${service.namespace} `}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.witdh25}
                          onMouseEnter={startHover(
                            `${service.name}/${service.namespace}`
                          )}
                          onMouseLeave={cancelHover}
                        >
                          <ServiceName
                            service={service}
                            onClick={doOpen(service.owner)}
                          />
                        </TableCell>
                        <TableCell className={classes.witdh15}>
                          <ServiceState status={service.service.status} />
                        </TableCell>
                        <TableCell className={classes.witdh15}>
                          <Tooltip
                            title={service.infinity.clusterAccountName}
                            placement="top-start"
                          >
                            <Typography>{service.namespace}</Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.witdh15}>
                          <Tooltip
                            title={service.service.version}
                            placement="right"
                          >
                            <Typography className={classes.versionText}>
                              {service.service.version}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.witdh15}>
                          <Tooltip
                            title={
                              <Moment format="ddd, DD.MM.YYYY HH:mm:ss (Z)">
                                {service.lastUpdate}
                              </Moment>
                            }
                            placement="right"
                          >
                            <Moment fromNow>{service.lastUpdate}</Moment>
                          </Tooltip>
                        </TableCell>
                        <TableCell className={classes.witdh5}>
                          <ServiceActions
                            service={service}
                            onOpenServiceOwner={doOpen(
                              service.owner,
                              service.tags
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} className={classes.center}>
                        No services found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              classes={{
                select: classes.paginatorPagesSelect,
              }}
              count={services.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100, 500]}
            />
          </Paper>
        );
      case "ERROR":
        return (
          <Grid container direction="row" justify="center" alignItems="center">
            <p>Error: {errorMessage}</p>
          </Grid>
        );
      default:
        return <p>Unknown state</p>;
    }
  }
);

export default ServiceList;
