import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Actions from "../actions";
import FilterBar from "../components/FilterBar";
import { Service } from "../models/Service";

export interface ApplicationState {
  services: Service[];
  namespaces: string[];
  filteredNamespaces: string[];
  toggleShowAll: boolean;
}

const mapStateToProps = (state: ApplicationState) => {
  // unique sorted list of namespaces
  const namespaces = state.services
    .map((s) => s.namespace)
    .sort()
    .filter((el, i, a) => i === a.indexOf(el));

  return {
    namespaces,
    filteredNamespaces: state.filteredNamespaces,
    toggleShowAll: state.toggleShowAll,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDefaultValue: Actions.setDefaultFilterFromQuery(),
  onLoadSetFilter: dispatch(Actions.dispatchSetFilterFromQuery()),
  onLoadSetNameSpaceFilter: dispatch(
    Actions.dispatchSetFilterNamespacesFromQuery()
  ),
  onFilterChange: (filter: string) =>
    dispatch(Actions.dispatchSetFilter(filter)),
  onFilterNamespaceChange: (namespaces: string[]) =>
    dispatch(Actions.dispatchSetFilterNamespaces(namespaces)),
  onToggleButtonChange: (toggleShowAll: boolean) => {
    dispatch(Actions.dispatchSetToggleButton(toggleShowAll));
    // @ts-ignore
    dispatch(Actions.actionFetchServices());
  },
});

const FilterBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterBar);

export default FilterBarContainer;
