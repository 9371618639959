import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FaceIcon from "@material-ui/icons/Face";
import React from "react";
import logo from "../infinity.png";
import User from "../models/User";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    appTitle: {
      marginRight: theme.spacing(3),
      paddingLeft: 60,
      backgroundImage: `url(${logo})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left",
    },
    blueAvatar: {
      color: "#fff",
      backgroundColor: "#2a7cca",
    },
    rightAligned: {
      marginLeft: "auto",
    },
    grow: {
      flexGrow: 1,
    },
    menuIcon: {
      marginRight: 6,
      color: "#FFFFFF",
    },
  });

interface Props extends WithStyles<typeof styles> {
  user: User;
}

const Header = withStyles(styles)(({ user, classes }: Props) => (
  <div className={classes.root}>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h5" color="inherit" className={classes.appTitle}>
          Infinity Dashboard
        </Typography>
        <Button
          size="small"
          target="_blank"
          href="https://docs.cloud.scout24.com/products/infinity/quickstart/"
          variant="text"
          color="inherit"
        >
          <Icon className={classes.menuIcon}>timer</Icon>
          Getting started
        </Button>
        <Button
          size="small"
          target="_blank"
          href="https://docs.cloud.scout24.com/products/infinity/"
          variant="text"
          color="inherit"
        >
          <Icon className={classes.menuIcon}>cloud</Icon>
          Docs
        </Button>
        <Button
          size="small"
          target="_blank"
          href="https://app.datadoghq.com/apm/map?env=pro"
          variant="text"
          color="inherit"
        >
          <Icon className={classes.menuIcon}>maps_home_work</Icon>
          Pro Service Map
        </Button>
        <div className={classes.grow} />
        <Chip
          avatar={
            <Avatar className={classes.blueAvatar}>
              <FaceIcon />
            </Avatar>
          }
          label={user ? user.name : "Retrieving user info..."}
          color="secondary"
        />
      </Toolbar>
    </AppBar>
  </div>
));

export default withStyles(styles)(Header);
