import { Action, Dispatch } from "redux";
import { apiBaseUrl } from "../config";
import { Service } from "../models/Service";
import User from "../models/User";
import { AppState } from "../reducers";
import { AuthError, getServices } from "../services/api";

export const ACTION_SERVICES_FETCH_SUCCESS = "SERVICES_FETCH_SUCCESS";
export const ACTION_SERVICES_FETCH_ERROR = "SERVICES_FETCH_ERROR";
export const ACTION_SERVICES_SET_FILTER = "SERVICES_SET_FILTER";
export const ACTION_SERVICES_SET_FILTER_NAMESPACES =
  "SERVICES_SET_FILTER_NAMESPACES";
export const ACTION_SERVICES_SET_TOGGLE = "SERVICES_SET_TOGGLE";
export const ACTION_SERVICES_SET_PAGE = "SERVICES_SET_PAGE";
export const ACTION_SERVICES_SET_ANNOUNCEMENT_BANNER =
  "SET_ANNOUNCEMENT_BANNER";

const announcementBannerState = "infinity-announcement-banner-state";

export interface ActionServicesFetchSuccess extends Action {
  type: "SERVICES_FETCH_SUCCESS";
  services: Service[];
  user: User;
}

export interface ActionServicesFetchError extends Action {
  type: "SERVICES_FETCH_ERROR";
  errorMessage: string;
}

export interface ActionServicesSetFilter extends Action {
  type: "SERVICES_SET_FILTER";
  filter: string;
}

export interface ActionServicesSetFilterNamespaces extends Action {
  type: "SERVICES_SET_FILTER_NAMESPACES";
  filteredNamespaces: string[];
}

export interface ActionServicesSetToggle extends Action {
  type: "SERVICES_SET_TOGGLE";
  toggleShowAll: boolean;
}

export interface ActionServicesSetPage extends Action {
  type: "SERVICES_SET_PAGE";
  page: number;
}

export interface SetAnnouncementBannerStateAction extends Action {
  type: "SET_ANNOUNCEMENT_BANNER";
  state: boolean;
}

export type AppActions =
  | ActionServicesFetchSuccess
  | ActionServicesFetchError
  | ActionServicesSetFilter
  | ActionServicesSetFilterNamespaces
  | ActionServicesSetToggle
  | SetAnnouncementBannerStateAction
  | ActionServicesSetPage;

export function dispatchFetchServicesSuccess(
  services: Service[],
  user: User
): ActionServicesFetchSuccess {
  return {
    type: ACTION_SERVICES_FETCH_SUCCESS,
    services,
    user,
  };
}

export function dispatchFetchServicesError(e: Error): ActionServicesFetchError {
  return {
    type: ACTION_SERVICES_FETCH_ERROR,
    errorMessage: e.message,
  };
}

export function dispatchSetFilter(filter: string): ActionServicesSetFilter {
  return {
    type: ACTION_SERVICES_SET_FILTER,
    filter,
  };
}

export function dispatchSetFilterFromQuery(): ActionServicesSetFilter {
  const filter = getQueryStringParams("GenericFilter");
  return {
    type: ACTION_SERVICES_SET_FILTER,
    filter,
  };
}

function getQueryStringParams(paramName: string, getAll: boolean = false): any {
  const params = new URLSearchParams(window.location.search);
  return getAll ? params.getAll(paramName) : params.get(paramName) || "";
}

export function setDefaultFilterFromQuery(): string {
  return getQueryStringParams("GenericFilter");
}

export function dispatchSetFilterNamespaces(
  namespaces: string[]
): ActionServicesSetFilterNamespaces {
  return {
    type: ACTION_SERVICES_SET_FILTER_NAMESPACES,
    filteredNamespaces: namespaces,
  };
}

export function dispatchSetFilterNamespacesFromQuery(): ActionServicesSetFilterNamespaces {
  const namespaces = getQueryStringParams("NameSpaceFilter", true);
  return {
    type: ACTION_SERVICES_SET_FILTER_NAMESPACES,
    filteredNamespaces: namespaces,
  };
}

export function dispatchSetToggleButton(
  toggleShowAll: boolean
): ActionServicesSetToggle {
  return {
    type: ACTION_SERVICES_SET_TOGGLE,
    toggleShowAll,
  };
}

export function dispatchSetPage(page: number): ActionServicesSetPage {
  return {
    type: ACTION_SERVICES_SET_PAGE,
    page,
  };
}

export function actionFetchServices() {
  return (dispatch: Dispatch, getState: () => AppState) => {
    return getServices(getState().toggleShowAll)
      .then((response) => {
        return dispatch(
          dispatchFetchServicesSuccess(response.services, response.user)
        );
      })
      .catch((e: Error) => {
        if (e instanceof AuthError) {
          window.location.href = `${apiBaseUrl}/auth?redirect_url=${window.location.pathname}${window.location.search}`;
          return;
        }
        return dispatch(dispatchFetchServicesError(e));
      });
  };
}

export const persistAnnouncementBannerState = (state: boolean) => {
  return async (dispatch: Dispatch) => {
    localStorage.setItem(announcementBannerState, state.toString());
    return dispatch(setAnnouncementBannerState(state));
  };
};

export const setAnnouncementBannerState = (
  state: boolean
): SetAnnouncementBannerStateAction => {
  return {
    type: ACTION_SERVICES_SET_ANNOUNCEMENT_BANNER,
    state,
  };
};

export const fetchAnnouncementBannerState = () => {
  return async (dispatch: Dispatch) => {
    const state = localStorage.getItem(announcementBannerState);
    return dispatch(
      setAnnouncementBannerState(state === "true" || state === null)
    );
  };
};
