const testResponse = {
  user: {
    email: "max.mustermann@scout24.com",
    name: "Max Mustermann",
    firstname: "Max",
    lastname: "Mustermann",
  },
  services: [
    {
      name: "restapicopytool",
      namespace: "is24-api",
      infinity: {
        version: "2",
        clusterAccountName: "s24-platform",
      },
      service: {
        version: "201",
        status: {
          state: "READY",
          replicas: 2,
          availableReplicas: 1,
          dns: "restapicopytool.scout24.com",
        },
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "is24-it-i18n-dev@immobilienscout24.de",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "owner",
      },
      lastUpdate: "2019-01-28T06:04:21Z",
      productAccountId: "1234",
    },
    {
      name: "image-scaling-is24",
      service: {
        ecsServiceName: "image-scaling-is24-Service-VB6N5VYHG5JG",
        datadogDashboardId: "123456",
        version: "966d977ece890e07aa94a6ed23698185afd108db",
        status: {
          state: "READY",
          replicas: 2,
          availableReplicas: 2,
          dns: "infinity-logging-reverse-proxy.scout24.com",
        },
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "",
        owner: "",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "segment",
      },
      infinity: {
        version: "1",
        clusterAccountName: "ecs",
      },
      namespace: "s24-platform",
      lastUpdate: "2019-01-28T13:59:56Z",
      productAccountId: "1234",
    },
    {
      name: "video-scaling-as24",
      service: {
        ecsServiceName: "image-scaling-as24-Service-VB6N5VYHG5JG",
        datadogDashboardId: "jeff_scou2!!!_4#@_michael",
        version: "966d977ece890e07aa94a6ed23698185afd108db",
        status: {
          state: "READY",
          replicas: 2,
          availableReplicas: 2,
          dns: "infinity-logging-reverse-proxy.scout24.com",
        },
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "",
        owner: "",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "segment",
      },
      infinity: {
        version: "1",
        clusterAccountName: "ecs",
      },
      namespace: "s24-platform",
      lastUpdate: "2019-01-28T13:59:56Z",
      productAccountId: "1234",
    },
    {
      name: "s24-showback",
      namespace: "s24-platform-dev",
      service: {
        status: {
          state: "NOT_READY",
          replicas: 2,
        },
        version: "0.9.6",
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "is24-it-i18n-dev@immobilienscout24.de",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "owner",
      },
      infinity: {
        version: "1",
        clusterAccountName: "ecs",
      },
      lastUpdate: "2019-01-28T13:37:10Z",
      productAccountId: "1234",
    },
    {
      name: "s24-showback-unknown",
      namespace: "s24-platform-dev",
      infinity: {
        version: "2",
        clusterAccountName: "s24-platform",
      },
      service: {
        version: "0.9.6",
        status: {
          state: "UNKNOWN",
          replicas: 2,
          availableReplicas: 1,
        },
      },
      owner: {},
      serviceCatalog: {
        organizationId: "",
        organizationName: "",
        matchingTag: "",
      },
      lastUpdate: "2019-01-28T13:37:10Z",
      productAccountId: "1234",
    },
    {
      name: "s24-showback-updfailed",
      namespace: "s24-platform-dev",
      infinity: {
        version: "2",
        clusterAccountName: "s24-platform",
      },
      service: {
        version: "0.9.6",
        status: {
          state: "UPDATE_FAILED",
          replicas: 2,
        },
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "is24-it-i18n-dev@immobilienscout24.de",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "owner",
      },
      lastUpdate: "2019-01-28T13:37:10Z",
      productAccountId: "1234",
    },
    {
      name: "missing-state",
      namespace: "s24-platform-dev",
      infinity: {
        version: "2",
        clusterAccountName: "s24-platform",
      },
      service: {
        version: "0.9.6",
        status: {
          state: "",
          replicas: 2,
        },
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "abc@example.com",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "abc@example.com",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "owner",
      },
      lastUpdate: "2019-01-28T13:37:10Z",
      productAccountId: "1234",
    },
    {
      name: "slack-ccebot",
      namespace: "s24-platform-dev",
      infinity: {
        version: "2",
        clusterAccountName: "s24-platform",
      },
      service: {
        version: "87",
        status: {
          state: "READY",
          replicas: 2,
          availableReplicas: 2,
        },
      },
      tags: {
        "component-id": "slack-ccebot-component",
        "service-id": "slack-ccebot-service",
        stage: "pro",
        team: "the-team",
        confidentiality: "public",
        product: "foo",
        foo: "bar",
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "is24-it-i18n-dev@immobilienscout24.de",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "owner",
      },
      lastUpdate: "2019-01-24T14:14:48Z",
      productAccountId: "1234",
    },
    {
      name: "christine-service",
      namespace: "s24-platform-dev",
      infinity: {
        version: "1",
        clusterAccountName: "ecs",
      },
      service: {
        version: "0.1",
        status: {
          state: "READY",
          replicas: 12,
          availableReplicas: 12,
        },
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "is24-it-i18n-dev@immobilienscout24.de",
      },
      serviceCatalog: {
        organizationId: "12345",
        organizationName: "Isö It",
        matchingTag: "team",
      },
      lastUpdate: "2019-01-24T14:14:48Z",
      productAccountId: "1234",
    },
    {
      name: "gerd-service",
      namespace: "s24-platform-dev",
      infinity: {
        version: "2",
        clusterAccountName: "s24-platform",
      },
      service: {
        version: "8.12",
        status: {
          errors: ["some error", "another error"],
          DNS: "gerd-service.scout24.com",
          state: "UPDATING",
          replicas: 4,
          availableReplicas: 2,
        },
      },
      tags: {
        "service-id": "example-service",
        stage: "dev",
        team: "other-team",
        product: "mgmt",
        confidentiality: "company-confidential-sensitive-pii",
        foo: "bar",
      },
      owner: {
        headOfTech:
          "Christian Schackerl \u003cchristian.schackerl@scout24.com\u003e",
        awsAccountEmail: "aws-int@immobilienscout24.de",
        vertical: "is24",
        segment: "austria",
        team: "is24 - at",
        owner: "",
      },
      serviceCatalog: {
        organizationId: "",
        organizationName: "",
        matchingTag: "",
      },
      lastUpdate: "2019-01-24T14:14:48Z",
      productAccountId: "1234",
    },
  ],
};

export const errorResponse = {
  error: "missing auth cookie 'i6y-session'",
};

export default testResponse;
