import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { defaultAppState, mainReducer } from "../reducers";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  mainReducer,
  defaultAppState(),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
