import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import TextField from "@material-ui/core/TextField";
import React from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudDownload";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-end",
      flexWrap: "wrap",
    },
    input: {
      margin: theme.spacing(2),
      width: theme.spacing(50),
    },
    select: {
      margin: theme.spacing(2),
      width: theme.spacing(25),
    },
    flexbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    rightIcon: {
      marginLeft: theme.spacing(),
      color: "#2a7cca",
    },
    showAllToggle: {
      marginBottom: 10,
    },
  });

interface Props extends WithStyles<typeof styles> {
  setDefaultValue: string;
  onLoadSetFilter: any;
  onLoadSetNameSpaceFilter: any;
  onFilterChange: any;
  onFilterNamespaceChange: any;
  onToggleButtonChange: any;
  toggleShowAll: boolean;
  namespaces: string[];
  filteredNamespaces: string[];
}

// eslint-disable-next-line jsx-a11y/anchor-has-content
const Link = (props: any) => <a {...props} />;

const FilterBar = withStyles(styles)(
  ({
    setDefaultValue,
    onLoadSetFilter,
    onLoadSetNameSpaceFilter,
    onFilterChange,
    onFilterNamespaceChange,
    onToggleButtonChange,
    toggleShowAll,
    namespaces,
    filteredNamespaces,
    classes,
  }: Props) => (
    <div className={classes.flexbox}>
      <form className={classes.container} noValidate autoComplete="off">
        <FormControl className={classes.select}>
          <InputLabel>Namespaces</InputLabel>
          <Select
            multiple
            onLoad={() => onLoadSetNameSpaceFilter()}
            onChange={(event) => onFilterNamespaceChange(event.target.value)}
            value={filteredNamespaces}
          >
            <MenuItem disabled value="">
              <em>Namespaces</em>
            </MenuItem>
            {namespaces.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          placeholder="Filter by Service Name or Namespace..."
          label="Filter"
          autoFocus={true}
          className={classes.input}
          onLoad={() => onLoadSetFilter()}
          onChange={(event) => onFilterChange(event.target.value.trim())}
          defaultValue={setDefaultValue}
        />
        <FormControl className={classes.showAllToggle}>
          <Tooltip
            title="By default you can only see the services running in the accounts that you have access to"
            placement="right"
          >
            <FormControlLabel
              control={
                <Switch
                  onChange={(event) =>
                    onToggleButtonChange(event.target.checked)
                  }
                  color="primary"
                  value={toggleShowAll}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="View All"
              labelPlacement="end"
            />
          </Tooltip>
        </FormControl>
      </form>
      <Button
        variant="outlined"
        size="medium"
        color="primary"
        component={Link}
        href={`/api/resources/export${toggleShowAll ? "?all=true" : ""}`}
      >
        Export
        <CloudUploadIcon className={classes.rightIcon} />
      </Button>
    </div>
  )
);

export default withStyles(styles)(FilterBar);
