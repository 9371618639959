import { connect } from "react-redux";
// @ts-ignore
import { withSizes } from "react-sizes";
import { Dispatch } from "redux";
import * as Actions from "../actions";
import ServiceList from "../components/ServiceList";
import { AppState } from "../reducers";

function filterAll(filter: string, service: any): boolean {
  const matcher = filter.match("^(\\?|=|!)(service.[^:]+): ?(.*)");
  if (matcher) {
    const filterCondition = matcher[1];
    const filterKey = matcher[2];
    const filterValue = matcher[3];
    for (const filterPart of filterKey.split(".").splice(1)) {
      service = service[filterPart];
      if (service === undefined) {
        break;
      }
    }
    if (service !== undefined) {
      if (typeof service === "number") {
        service = String(service);
      }
      if (typeof service === "string") {
        if (filterCondition === "?") {
          return service.toLowerCase().includes(filterValue.toLowerCase());
        } else if (filterCondition === "=") {
          return service === filterValue;
        } else if (filterCondition === "!") {
          return service !== filterValue;
        }
      }
    }
  }
  return false;
}

export const mapStateToProps = (state: AppState) => {
  let filteredServices = state.services;
  if (state.filteredNamespaces.length !== 0) {
    filteredServices = filteredServices.filter((service) =>
      state.filteredNamespaces.includes(service.namespace.toLocaleLowerCase())
    );
  }
  if (state.filter != null) {
    filteredServices = filteredServices.filter((service) => {
      const matchesServiceName = service.name
        .toLocaleLowerCase()
        .includes(state.filter.toLocaleLowerCase());

      const matchesNamespace = service.namespace
        .toLocaleLowerCase()
        .includes(state.filter.toLocaleLowerCase());

      return (
        matchesServiceName ||
        matchesNamespace ||
        filterAll(state.filter, service)
      );
    });
  }

  return {
    services: filteredServices,
    fetchState: state.fetchState,
    errorMessage: state.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPage: (page: number) => dispatch(Actions.dispatchSetPage(page)),
  loadData: () => {
    // @ts-ignore
    setInterval(() => dispatch(Actions.actionFetchServices()), 5000);
    // @ts-ignore
    dispatch(Actions.actionFetchServices());
  },
});

const ServiceListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(ServiceList);

// @ts-ignore
const mapSizesToProps = ({ width }) => ({
  width,
});

export default withSizes(mapSizesToProps)(ServiceListContainer);
