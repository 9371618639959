import { Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import React from "react";
import { Service } from "../models/Service";

interface Props extends WithStyles<typeof styles> {
  service: Service;
  onClick(): void;
}

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    infoIcon: {
      color: "#4EA5DF",
    },
    versionDocLink: {
      textDecoration: "none",
    },
    tooltipOpenInformation: {
      cursor: "pointer",
    },
  });

const ServiceName = withStyles(styles)(({ service, classes }: Props) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.root}>{service.name}</Typography>
    </div>
  );
});

export default ServiceName;
