import { Card } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAnnouncementBannerState,
  persistAnnouncementBannerState,
} from "../actions";

const styles = () =>
  createStyles({
    root: {
      transition: ".2s ease-out",
      opacity: 0,
      height: 0,
    },
    visibleBanner: {
      opacity: 1,
      height: "auto",
    },
    banner: {
      padding: "24px",
      textAlign: "center",
    },
  });

interface Props extends WithStyles<typeof styles> {}

const AnnouncementBanner = withStyles(styles)(({ classes }: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAnnouncementBannerState());
    setTimeout(() => {
      dispatch(persistAnnouncementBannerState(false));
    }, 15000);
  }, [dispatch]);

  if (process.env.REACT_APP_SHOW_BANNER !== "TRUE") {
    return null;
  }
  return (
    <div>
      <Card className={classes.banner}>{/* Insert announcement here */}</Card>
    </div>
  );
});

export default AnnouncementBanner;
